import React, { useState } from 'react';
import { sendPartnershipsForm } from '../../models/api';
import { trackEvent } from '../../models/analytics';
import Spinner from './Spinner';

const checkboxes = [
	{
		id: 'newChannel',
		label: 'Find new channels to distribute my insurance',
	},
	{
		id: 'manageEmbedded',
		label: 'Manage the insurance I\'m already embedding',
	},
	{
		id: 'addInsurance',
		label: 'Add insurance to my application',
	},
	{
		id: 'moreInterest',
		label: 'All of the above',
	},
];

const initialFormState = {
	firstName: '',
	lastName: '',
	email: '',
	company: '',
};

const isValidEmail = (value) => /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(value);

const CheckBox = ({
	id,
	onChange,
	checked,
	label,
}) => (
	<div className="relative flex items-start">
		<div className="flex items-center h-5">
			<input
				id={id}
				name={id}
				checked={checked}
				onChange={onChange}
				type="checkbox"
				className="focus:ring-indigo-500 h-4 w-4 border-gray-300 rounded"
			/>
		</div>
		<div className="ml-3 text-sm">
			<label htmlFor={id} className="font-medium text-gray-700">
				{ label }
			</label>
		</div>
	</div>
);

export default function ContactForm() {
	const [form, setForm] = useState(initialFormState);
	const [wishes, setWishes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const {
		firstName,
		lastName,
		email,
		company,
	} = form;

	const handleChange = ({ target: { name, value } }) => {
		if (name === 'email') {
			setEmailError(false);
		}
		setForm((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleCheck = ({ target: { name, checked } }) => {
		if (checked) {
			setWishes((prev) => ([...prev, name]));
		} else {
			setWishes((prev) => prev.filter((el) => el !== name));
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setSubmitError(false);
		setIsLoading(true);
		const options = { ...form };
		const msgOptions = checkboxes.reduce((prev, curr) => {
			let msg = prev;
			if (wishes.includes(curr.id)) {
				msg += ` \n${curr.label}`;
			}
			return msg;
		}, '');
		options.message = `I would like to:${msgOptions}`;
		try {
			await sendPartnershipsForm(options);
			trackEvent({ eventType: 'form_submit' });
			setIsSubmitted(true);
		} catch (error) {
			// TODO: hook up sentry to ping us about this error.
			let msg = error?.message || error?.error || error;
			if (typeof msg !== 'string') {
				msg = 'An unknown error occurred.';
			}
			setSubmitError(msg);
		}
		setIsLoading(false);
	};

	const validateEmail = () => {
		if (email.length && !isValidEmail(email)) {
			setEmailError('Please use a valid email address.');
		}
	};

	const canSubmit = !!(firstName.length && lastName.length && isValidEmail(email));

	if (isSubmitted) {
		return (
			<div className="py-8 md:py-16">
				<div className="text-center text-6xl md:text-9xl mb-8">
					🎉
				</div>
				<h3>
					We're stoked to hear from you!
				</h3>
				<p className="pt-4">
					We're getting a ton of requests right now, so we'll be in touch as soon as we can.
				</p>
			</div>
		);
	}

	return (
		<form className="mt-4 md:mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
			<div>
				<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
					First name
				</label>
				<div className="mt-1">
					<input
						type="text"
						name="firstName"
						onChange={handleChange}
						value={firstName}
						id="first-name"
						autoComplete="given-name"
						className="py-3 px-4 block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
					/>
				</div>
			</div>
			<div>
				<label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
					Last name
				</label>
				<div className="mt-1">
					<input
						type="text"
						name="lastName"
						onChange={handleChange}
						value={lastName}
						id="last-name"
						autoComplete="family-name"
						className="py-3 px-4 block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
					/>
				</div>
			</div>
			<div className="sm:col-span-2">
				<label htmlFor="email" className="block text-sm font-medium text-gray-700">
					Email
				</label>
				<div className="mt-1">
					<input
						id="email"
						name="email"
						onChange={handleChange}
						onBlur={validateEmail}
						value={email}
						type="email"
						autoComplete="email"
						className="py-3 px-4 block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
					/>
					{emailError ? <small className="text-red-400">{emailError}</small> : null}
				</div>
			</div>
			<div className="sm:col-span-2">
				<label htmlFor="company" className="block text-sm font-medium text-gray-700">
					Company
				</label>
				<div className="mt-1">
					<input
						type="text"
						name="company"
						onChange={handleChange}
						value={company}
						id="company"
						autoComplete="organization"
						className="py-3 px-4 block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
					/>
				</div>
			</div>
			<fieldset className="sm:col-span-2">
				<label htmlFor="select" className="block text-sm font-medium text-gray-700">
					I would like to...
				</label>
				<div className="mt-1">
					{checkboxes.map(({ id, label }) => (
						<CheckBox
							id={id}
							key={id}
							label={label}
							checked={wishes.includes(id)}
							onChange={handleCheck}
						/>
					))}
				</div>
			</fieldset>
			{/* <div className="sm:col-span-2">
    <div className="mt-1">
      <textarea
        id="how-can-we-help"
        name="how-can-we-help"
        aria-describedby="how-can-we-help-description"
        rows={4}
        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border border-gray-300 rounded-md"
        defaultValue=""
        placeholder=" Hi Buddy, I would like to learn more about this.."

      />
    </div>
  </div> */}
			<div className="my-10">
				<button
					type="submit"
					className="buddy-btn-mobelux disabled:cursor-not-allowed disabled:opacity-50 w-full"
					onClick={handleSubmit}
					disabled={isLoading || !canSubmit}
				>
					{isLoading ? <Spinner /> : 'Submit'}
				</button>
				{submitError ? <small className="text-red-400 mt-4 block">{submitError}</small> : null}
			</div>
		</form>
	);
}
