import * as React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Surfer from '../components/subcomponents/Surfer';
import ContactForm from '../components/subcomponents/ContactForm';
import SEO from '../components/SEO';

// const Surfer = loadable(() => import('../components/subcomponents/Surfer'));

export default function ContactUs(props) {
	return (
		<SEO {...props} title="Contact Buddy | The insurance gateway for software applications" description="Contact Buddy to learn more about our drop-in snippet enables you to embed any insurance product into your transaction flow in minutes.">
			<main>
				<Header />
				<div className="relative bg-white">
					<div className="absolute inset-0">
						<div className="absolute inset-y-0 left-0 w-1/2 bg-white" />
					</div>
					<div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5 flex flex-col-reverse">
						<div className="bg-white py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
							<div className="flex flex-col">
								<Surfer className="lg:w-auto md:w-80 w-64 mx-auto lg:mx-0 " />
								<dl className="text-base text-gray-500">

									<div className="mt-6">
										<dt className="sr-only">Postal address</dt>
										<dd>
											<p>P.O. Box 81</p>
											<p>Richmond, VA 23218-0081</p>
										</dd>
									</div>
									<div className="mt-6">
										<dt className="sr-only">Phone number</dt>
										<dd className="flex">
											<a
												className="text-gray-500 hover:text-gray-900"
												href="tel:+18334628339"
											>
												833.462.8339
											</a>
										</dd>
									</div>
									<div className="mt-3">
										<dt className="sr-only">Email</dt>
										<dd className="flex">
											<a
												className="text-gray-500 hover:text-gray-900"
												href="mailto:buddy@buddy.insure"
											>
												buddy@buddy.insure
											</a>
										</dd>
									</div>
								</dl>
							</div>
						</div>
						<div className="gradient py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
							<div className="max-w-lg mx-auto lg:max-w-none">
								<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Get In Touch.</h2>
								<p className="mt-3 text-lg leading-6 text-gray-500">
									Buddy’s insurance gateway simplifies adding insurance to your software.
								</p>
								<ContactForm />
							</div>
						</div>
					</div>
				</div>
				<div className="mt-24">
					<Footer />
				</div>
			</main>
		</SEO>
	);
}
