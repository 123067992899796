const PARTNERSHIPS_FORM_URL = process.env.GATSBY_PARTNERSHIPS_FORM_URL;

// helper function to generate the FETCHES
function createFetch({ url, method = 'GET', body = null }) {
	const obj = {
		method,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	};

	if (body) {
		obj.body = JSON.stringify(body);
	}

	return fetch(url, obj);
}

// use send form submission to HubSpot
/**
 * @param {Object} options
 * @param {string} options.firstname
 * @param {string} options.lastname
 * @param {string} options.email
 * @param {string} options.company
 * @param {string} options.message
 */
// eslint-disable-next-line import/prefer-default-export
export const sendPartnershipsForm = async (options) => {
	const body = {
		context: {
			pageUri: 'https://buddy.insure',
			pageName: 'Partnerships',
		},
	};

	// convert to array of objects with name & value keys.
	body.fields = Object.keys(options).map((key) => ({
		// lower casing here in case we camel-cased it. Hubspot only wants lowercase
		name: key.toLowerCase(),
		value: options[key],
	}));

	try {
		const res = await createFetch({
			url: PARTNERSHIPS_FORM_URL,
			method: 'POST',
			body,
		});
		const json = await res.json();
		if (json.status === 'error') {
			return Promise.reject(json.message || json);
		}
		return json;
	} catch (error) {
		return Promise.reject(error);
	}
};
